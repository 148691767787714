import { FC } from 'react';
import IconProps from './IconProps';

const UserIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1647 7.33333C15.1647 9.17428 13.6723 10.6667 11.8314 10.6667C9.99043 10.6667 8.49805 9.17428 8.49805 7.33333C8.49805 5.49238 9.99043 4 11.8314 4C13.6723 4 15.1647 5.49238 15.1647 7.33333Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8333 13.1667C8.61168 13.1667 6 15.7783 6 19H17.6667C17.6667 15.7783 15.055 13.1667 11.8333 13.1667Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
